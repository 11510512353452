import styled from "@emotion/styled";
import { Markdown } from "../markdown";
import { Page } from "../page";

const title = "Privacy 隐私条款";

const content = `
# 隐私条款

本公司非常重视您的个人隐私，因此我们制定了以下隐私政策，以保证您的个人信息得以安全保护。

## 信息收集

我们可能会收集以下类型的个人信息：

- 姓名、地址、电子邮件地址和电话号码等联系方式。
- 支付信息，例如信用卡号码。
- 设备信息，例如操作系统、IP地址和浏览器类型。
- 在我们的网站上使用的数据，例如搜索查询、浏览历史和日期/时间戳。

## 信息的使用

我们收集的个人信息将用于以下一些方面：

- 为您提供所请求的服务。
- 处理您的付款以及向您发送账单。
- 为您提供有关我们产品和服务的信息。
- 改进和管理我们的网站和服务。
- 防止欺诈和其他非法行为。

## 信息的共享
我们不会将您的个人信息出售、出租或出借给任何第三方。但是，我们可能会向以下一些方面共享您的信息：
- 与我们的合作伙伴共享您的信息，以提供您请求的服务。
- 向我们的第三方服务提供商提供您的信息，以帮助我们提供服务。
- 遵守法律要求或合法程序，例如传票、法院命令或其他法律程序

## 信息的保护
我们采用适当的安全措施来保护您的个人信息，以防止未经授权的访问、使用、修改或披露。我们定期审查我们的安全政策和程序，以确保我们的系统安全。

## 隐私政策的变更
我们可能会不时更新本隐私政策。我们建议您定期查看本页面以了解对隐私政策的任何更改。如果我们对隐私政策进行重大更改，我们将在我们的网站上发布通知。
如果您有任何有关我们的隐私政策的问题，请联系我们。谢谢。
`;

const Container = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    padding-top: 2rem;
    padding-bottom: 3rem;

    .inner {
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;
        font-weight: "Work Sans", sans-serif;

        * {
            color: white !important;
        }

        h1, h2 {
            border-bottom: thin solid rgba(255, 255, 255, 0.2);
            padding-bottom: 1rem;
            margin-bottom: 1rem;
        }
    }
`;

export default function PrivacyPage(props: any) {
    return <Page id={'privacy'} headerProps={{}}>
        <Container>
            <Markdown content={content} className='inner' />
        </Container>
    </Page>;
}