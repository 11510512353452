import styled from '@emotion/styled';
import { Button, ActionIcon, Textarea, NativeSelect } from '@mantine/core';
import { useCallback, useEffect, useInsertionEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppContext } from '../context';

const Container = styled.div`
    background: #292933;
    border-top: thin solid #393933;
    padding: 1rem 1rem 0 1rem;

    .inner {
        max-width: 50rem;
        margin: auto;
        text-align: right;
    }

    .settings-button {
        margin: 0.5rem -0.4rem 0.5rem 1rem;
        font-size: 0.7rem;
        color: #999;
    }
`;

export declare type OnSubmit = (name?: string) => Promise<boolean>;

function PaperPlaneSubmitButton(props: { onSubmit: any, disabled?: boolean }) {
    return (
        <ActionIcon size="sm"
            disabled={props.disabled}
            loading={props.disabled}
            onClick={props.onSubmit}>
            <i className="fa fa-paper-plane" style={{ fontSize: '90%' }} />
        </ActionIcon>
    );
}

export interface MessageInputProps {
    disabled?: boolean;
}

export default function MessageInput(props: MessageInputProps) {
    const context = useAppContext();
    const pathname = useLocation().pathname;
    const [placeholder, setPlaceholder] = useState(props.disabled ? "点击右上角“注册”，即可开启免费试用" : "请输入你的问题");
    const [promptTemplateName, setPromptTemplateName] = useState("自定义问答")
    const [templateVars, setTemplateVars] = useState({})

    // ["自定义问答", "AAA",'翻译为', '大纲提炼', '内容改写', "文章润色", "解释代码", "Fix Bug"]
    const promptInputPlaceholders = {
        自定义问答: { placeholder: "请输入你的问题", userPrompts: [""] },
        翻译为: {
            placeholder: "请直接输入需要翻译的内容",
            userPrompts: [
                "Translate the following content to ",
                [
                    { label: "中文", value: "Chinese" },
                    { label: "英文", value: "English" },
                    { label: "西班牙文", value: "Spanish" },
                    { label: "日文", value: "Japanese" },
                    { label: "韩文", value: "Korean" },
                ],
                " language:",
            ],
        },
        概括总结: {
            placeholder: "请输入需要总结概括的内容",
            userPrompts: [
                "请言简意赅概括总结以下内容: ",
            ],
        },
        内容改写: {
            placeholder: "请输入需要改写的内容",
            userPrompts: ["请重新改写以下内容: "],
        },
        代码解释: {
            placeholder: "请输入需要理解的代码",
            userPrompts: ["Explain the following codes: "],
        },
        Debug: {
            placeholder: "请输入相关的代码及错误提示",
            userPrompts: ["Based on the following codes and error message, try to resolve the issue: "],
        },
    };

    useEffect(() => {
        if (!props.disabled) {
            setPlaceholder(promptInputPlaceholders[promptTemplateName]["placeholder"]);
        }

        setTemplateVars(promptInputPlaceholders[promptTemplateName]['userPrompts'].reduce((acc, val, ind) => {
            if (typeof val !== 'string') {
                acc[ind] = val[0].value;
            }

            return acc;
        }, {})
        )
    }, [props.disabled, promptTemplateName]);

    const onChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        context.setMessage(e.target.value);
    }, [context]);

    const onSubmit = useCallback(async () => {
        const userPrompt = promptInputPlaceholders[promptTemplateName]['userPrompts'].reduce((acc, val, ind) => {
            if (typeof val !== 'string') {
                acc += templateVars[ind];
            } else {
                acc += val;
            }
            return acc;

        }, "")
        if (await context.onNewMessage(context.message, userPrompt)) {
            context.setMessage('');
        }
    }, [context]);

    const onKeyDown = useCallback((e) => {
        if (e.key === 'Enter' && e.shiftKey === false && !props.disabled) {
            e.preventDefault();
            // onSubmit();
            context.setMessage(e.target.value + "\n");
        }
    }, [onSubmit, props.disabled]);

    const rightSection = useMemo(() => {
        return (
            <div style={{
                opacity: '0.8',
                paddingRight: '0.4rem',
            }}>
                <PaperPlaneSubmitButton onSubmit={onSubmit} disabled={props.disabled} />
            </div>
        );
    }, [onSubmit, props.disabled]);

    const openSystemPromptPanel = useCallback(() => context.settings.open('options', 'system-prompt'), [context.settings]);
    const openTemperaturePanel = useCallback(() => context.settings.open('options', 'temperature'), [context.settings]);

    const messagesToDisplay = context.currentChat.messagesToDisplay;
    const disabled = context.generating
        || messagesToDisplay[messagesToDisplay.length - 1]?.role === 'user'
        || (messagesToDisplay.length > 0 && !messagesToDisplay[messagesToDisplay.length - 1]?.done);

    const isLandingPage = pathname === '/';
    if (context.isShare || (!isLandingPage && !context.id)) {
        return null;
    }


    const handleSelectChange = (event) => {
        setPromptTemplateName(event.target.value);
    };

    const handleTemplateVarSelectChange = (event) => {
        setTemplateVars({ ...templateVars, [event.target.dataset.index]: event.target.value })
    };

    return <Container>
        <div className="inner">
            <div style={{ display: "flex" }}>
                <NativeSelect styles={{
                    root: {
                        display: "flex",
                        flexDirection: "row"
                    }
                }}
                    style={{ display: "inline-block" }}
                    onChange={handleSelectChange}
                    data={Object.keys(promptInputPlaceholders)}
                    label=""
                />
                {promptInputPlaceholders[promptTemplateName]["userPrompts"].map((x, ind) => {
                    if (typeof x === "string") {
                        return <></>;
                    } else {
                        return <NativeSelect styles={{
                            root: {
                                display: "flex",
                                flexDirection: "row"
                            }
                        }}

                            style={{ display: "inline-block" }}
                            onChange={handleTemplateVarSelectChange}
                            data-index={ind}
                            data={x}
                            label=""
                        />
                    }
                })}
            </div>
            <br />
            <Textarea disabled={props.disabled || disabled}
                autosize
                minRows={3}
                maxRows={12}
                placeholder={placeholder}
                value={context.message}
                onChange={onChange}
                rightSection={rightSection}
                onKeyDown={onKeyDown} />
            <div>
                <Button variant="subtle"
                    className="settings-button"
                    size="xs"
                    compact
                    component={Link} to={"/terms"}>
                    <span>服务条款</span>
                </Button>
                <Button variant="subtle"
                    className="settings-button"
                    size="xs"
                    compact
                    component={Link} to={"/privacy"}>
                    <span>隐私条款</span>
                </Button>
            </div>
        </div>
    </Container>;
}