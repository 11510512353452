import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {MantineProvider} from '@mantine/core';
import {ModalsProvider} from '@mantine/modals';
import {AppContextProvider} from './context';
import LandingPage from './components/pages/landing';
import ChatPage from './components/pages/chat';
import AboutPage from './components/pages/about';
import TermsPage from "./components/pages/terms";
import {Auth0Provider} from "@auth0/auth0-react";

import './index.scss';
import PrivacyPage from "./components/pages/privacy";

const router = createBrowserRouter([
    {
        path: "/",
        element: <AppContextProvider>
            <LandingPage landing={true}/>
        </AppContextProvider>,
    },
    {
        path: "/chat/:id",
        element: <AppContextProvider>
            <ChatPage/>
        </AppContextProvider>,
    },
    {
        path: "/s/:id",
        element: <AppContextProvider>
            <ChatPage share={true}/>
        </AppContextProvider>,
    },
    {
        path: "/s/:id/*",
        element: <AppContextProvider>
            <ChatPage share={true}/>
        </AppContextProvider>,
    },
    {
        path: "/about",
        element: <AppContextProvider>
            <AboutPage/>
        </AppContextProvider>,
    },{
        path: "/terms",
        element: <AppContextProvider>
            <TermsPage/>
        </AppContextProvider>,
    },{
        path: "/privacy",
        element: <AppContextProvider>
            <PrivacyPage/>
        </AppContextProvider>,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Auth0Provider
        domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
        clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
        authorizationParams={{
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            redirect_uri: window.location.origin
        }}
    >
        <React.StrictMode>
            <MantineProvider theme={{colorScheme: "dark"}}>
                <ModalsProvider>
                    <RouterProvider router={router}/>
                </ModalsProvider>
            </MantineProvider>
        </React.StrictMode>
    </Auth0Provider>
);