import { Parameters } from "./types";

export const defaultParameters: Parameters = {
    temperature: 0.5,
};

export function loadParameters(id: string | null | undefined = null, accessToken: string): Parameters {
    const key = id ? `parameters-${id}` : 'parameters';
    try {
        const raw = localStorage.getItem(key);
        if (raw) {
            const parameters = JSON.parse(raw) as Parameters;
            parameters.accessToken = accessToken;
            return parameters;
        }
    } catch (e) { }
    return id ? loadParameters(id, '') : { ...defaultParameters,  accessToken};
}

export function saveParameters(id: string, parameters: Parameters) {
    if (parameters) {
        localStorage.setItem(`parameters-${id}`, JSON.stringify(parameters));
        localStorage.setItem('parameters', JSON.stringify(parameters));
    }
}