import styled from "@emotion/styled";
import { Markdown } from "../markdown";
import { Page } from "../page";

const title = "服务条款 Terms of Service";

const content = `
# 服务条款 Terms of Service

在使用本服务前，您必须仔细阅读此免责声明及所有与此相关的服务条款和使用规则。如果您不同意此免责声明中的任何条款，请勿使用本服务。如果您使用本服务，则表示您已经接受并同意所有条款。
1. 介绍使用旷文OpenChat即表示您确认接受并同意受这些条款和条件的约束。
2. 同意条款和条件本协议于您首次使用旷文OpenChat应用程序的日期生效。
3. 退款政策我们允许在购买后的14天内退款，不需要提供理由。如果您对产品不满意，请联系我们。
4. 产品使用kwopenchat.com相关服务，您同意从与您的付款帐户链接的电子邮件或您用于注册帐户的电子邮件接收kwopenchat.com的重要产品更新。您可以通过单击每封电子邮件底部的“取消订阅”链接随时退出此产品更新。我们仅发送重要的产品更新。
5. 数据传输许可：
    
    - 你的数据将在你所在国家或地区之外进行处理
    - 为了使此产品按预期运行，我们必须将你的数据传输到位于你所在国家或地区之外的 LLM 数据中心进行处理。继续使用此产品即表示你同意此数据传输。
    - 如果不同意此数据传输，则可以选择停止使用产品。
    - kwopenchat.com将根据 [隐私条款](/privacy) 处理和传输你的数据。

## 免责申明



1. 本服务仅供非中国内地用户使用。如果您是中国内地用户，请考虑停止使用本服务。我们不对中国内地用户使用本服务所引起的任何法律责任承担任何责任。
2. 在申请使用本服务前，您应该确认您符合所有相关法律法规的规定，并且您应该遵循我们的服务条款和使用规定。
3. 我公司服务提供的内容均由第三方人工智能大语言模型直接提供，我公司不进行任何内容审查及修改，我们不保证本服务的安全性、稳定性、准确性、完整性、实用性、适用性等特定效果及结果，也不能保证本服务能够满足您的需要。
4. 我们提供的服务能可能会在未来发生变化，包括添加和删除功能，限制在免费/付费计划中提供的功能列表，开放一些或全部应用程序源代码。在这种情况下，您将不会收到退款（除非您的购买仍在14天退款期内）。
5. 无法保证我们的服务将满足您的要求或其操作将不间断或无错误。本协议未规定的所有明示和默示保证或条件（包括但不限于利润损失，数据损失或破坏，业务中断或合同损失），只要该排除或免责在适用法律下被允许，均予排除并明确否认。本协议不影响您的法定权利。我们对您因使用本服务而导致的任何损失，包括但不限于财产损失、商业利润损失、声誉损失等，不承担任何责任，即使在我们知晓或应该知晓该损失的情况下。
6. 我们将尽最大努力保持服务的可用性和运行，但我们无法保证服务的可用性。在自然灾害、所有权更改、依赖性服务中断以及其他使我们无法保持服务运行的原因发生时，您将不会获得退款（除非您的购买仍在14天退款期内）。我们保留在任何时候变更或终止本服务、限制或拒绝用户使用本服务的权利。我们无需通知用户或承担任何责任。
7. 本免责声明适用于我们的所有服务，包括但不限于网站、应用和其他服务。


`;

const Container = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    padding-top: 2rem;
    padding-bottom: 3rem;

    .inner {
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;
        font-weight: "Work Sans", sans-serif;

        * {
            color: white !important;
        }

        h1, h2 {
            border-bottom: thin solid rgba(255, 255, 255, 0.2);
            padding-bottom: 1rem;
            margin-bottom: 1rem;
        }
    }
`;

export default function TermsPage(props: any) {
    return <Page id={'terms'} headerProps={{}}>
        <Container>
            <Markdown content={content} className='inner' />
        </Container>
    </Page>;
}