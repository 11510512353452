import styled from '@emotion/styled';
import { Button, List } from '@mantine/core';
import { useAppContext } from '../../context';
import { Page } from '../page';

const Container = styled.div`
  flex-grow: 1;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Work Sans", sans-serif;
  line-height: 1.7;
  gap: 1rem;
`;

export default function LandingPage(props: any) {
    const context = useAppContext();

    return <Page id={'landing'} showSubHeader={true}>
        <Container>
            {context.authenticated ?
                <p>您好，请问有什么可以帮到您？</p>
                :
                <List>
                    <List.Item><i className="fa fa-check"></i> 原生 ChatGPT4 完整体验</List.Item>
                    <List.Item><i className="fa fa-check"></i> 强大命令库，支持数十种场景定制命令</List.Item>
                    <List.Item><i className="fa fa-check"></i> 聊天数据本地存储，隐私安全</List.Item>
                </List>
            }
        </Container>
    </Page>;
}